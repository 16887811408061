import { computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default function activeContactGroup() {
    const store = useStore();
    const route = useRoute();

    const sections = computed(() => {
        return store.state.articles.sections;
    });
    const activeSection = computed(() => {
        let value;
        if (route.params.sid) {
            value = sections.value.find((item) => item.id === route.params.sid);
        } else {
            value = sections.value[0];
        }
        return value || {};
    });
    watchEffect(() => {
        store.commit('articles/setActiveSection', activeSection.value);
    });
    return {
        activeSection,
    };
}
