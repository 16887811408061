<template>
  <label class="checkbox">
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="modelValue"
      @change="updateValue"
      class="checkbox__input"
    />
    <span class="checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
        disabled: Boolean,
        label: String,
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.checked);
        },
    },
};
</script>

<style lang="sass" scoped>
.checkbox
  width: fit-content
  display: flex
  align-items: center
  gap: 8px
  cursor: pointer
  height: 26px
  .checkbox__input
    cursor: pointer
    width: 16px
    height: 16px
    border-radius: 4px
    border: 1px solid #A9A9A9
    &:checked
      position: relative
      background: $gray
      border-color: #545454
      &::after
        content:''
        width: 12px
        height: 12px
        top: 50%
        left: 50%
        transform: translateX(-50%) translateY(-50%)
        position: absolute
        background-image: url(../../assets/img/for-sprite/check-light.svg)
  .checkbox__label
    color: #545454
    font-size: 16px
    font-weight: 400
    line-height: 25.6px

</style>
