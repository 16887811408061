<template>
  <div>
      <div class="popup__content ml16">
          <svg-icon :name="'warning'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
          <div class="text-size-26 text-bold text-center mt12">Вы не заполнили обязательные поля</div>
          <div class="text-size-14 text-center contnent-center mt8">
            <p>К таким полям относятся:</p>
            <ul class="styled_list text-center">
              <li>
                Заголовок
              </li>
              <li>
                Текст статьи
              </li>
              <li>
                Тип контента
              </li>
              <li>
                Вид контента
              </li>
              <li>
                Источник контента
              </li>
            </ul>
            <p class="mt8">
              Внимательно проверьте каждое из этих полей
            </p>
          </div>
          <div class="df mt32">
              <button class="btn" @click="close()">
                  <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                  Подтвердить
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    name: 'ArticleErrorModal',
    components: {
        SvgIcon,
    },
    mounted() {},
    data() {
        return {
            link: {
                value: '',
                error: false,
            },
        };
    },
    emits: ['set'],
    methods: {
        close() {
            this.$store.commit('popups/close', { name: this.popups.articleErrorModal.name });
        },
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
        }),
    },
};
</script>

<style scoped>

</style>
