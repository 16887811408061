<template>
    <!-- Шапка перемещения правила -->
    <div class="detail__moving-header --plug"></div>
    <div class="detail__moving-header">
        <div class="">

        </div>
        <div class="close" @click="$router.push(detailPath)">
            <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
        </div>
    </div>

    <!-- Перемещение правила -->
    <div class="content">
        <div class="container --moving-rule">
            <aside-sticky :marginTop="92" :marginBottom="0" class="--moving-rule">
                <aside class="aside aside--left aside--catalog">
                    <moving-aside-sections></moving-aside-sections>
                </aside>
            </aside-sticky>
            <div class="container-scroll container__right --moving-rule">
                <div class="move-block">
                    <div class="text-color-yellow">
                        <svg-icon :name="'move'" :width="80" :height="80" ></svg-icon>
                    </div>
                    <div class="text-center text-size-26 text-bold mt12">
                        Переместить статью
                    </div>
                    <div class="text-size-14 mt8">
                        Внимание! Вместе с категорией изменится автор статьи!
                    </div>
                    <div class="destination mt48">
                        <div class="destination__item">
                            <div class="text-size-13 text-bold">
                                {{ activeSection.name }}
                            </div>
                        </div>
                        <svg-icon :name="'arrow'" :width="32" :height="32"></svg-icon>
                        <div class="destination__item" :class="{'error': error}">
                            <div class="text-size-13 text-bold">
                                <span v-if="movingSection.name">{{ movingSection.name }}</span>
                                <span v-else>ВЫБЕРИТЕ РАЗДЕЛ</span>
                            </div>
                        </div>
                    </div>
                    <div class="authors df mt16">
                        <div class="article-author">
                            <div class="photo" style="-webkit-background-size: contain;background-size: contain;"
                                 :style="{'background-image': 'url('+appPath+currentAuthor.avatar+')'}"></div>
                            <div class="info">
                                <div class="name">
                                    {{ currentAuthor.name }}
                                </div>
                                <div class="cat mt4">
                                    {{ currentAuthor.post }}
                                </div>
                            </div>
                        </div>
                        <div class="article-author" v-if="movingSection.name">
                            <div class="photo" style="-webkit-background-size: contain;background-size: contain;"
                                 :style="{'background-image': 'url('+appPath+movingAuthor.avatar+')'}"></div>
                            <div class="info">
                                <div class="name">
                                    {{ movingAuthor.name }}
                                </div>
                                <div class="cat mt4">
                                    {{ movingAuthor.post }}
                                </div>
                            </div>
                        </div>
                        <div class="article-author --ghost" v-else>
                            <div class="photo"></div>
                            <div class="info">
                                <div class="name">
                                </div>
                                <div class="cat mt4">
                                </div>
                            </div>
                        </div>
                    </div>
                    <button  @click="send()" type="button" name="button" class="btn text-size-14 mt48">
                        <svg-icon :name="'check'" :width="16" :height="16" class="mr4"></svg-icon>
                        ПОДТВЕРДИТЬ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import AsideSticky from '@/components/AsideSticky.vue';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';
import MovingAsideSections from '@/components/detail/MovingAsideSections.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    components: {
        AsideSticky,
        SvgIcon,
        MovingAsideSections,
    },
    props: {
        detail: {
            default() {
                return {};
            },
        },
        detailPath: {
            default() {
                return '/';
            },
        },
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    data() {
        return {
            understand: {
                value: false,
                error: false,
            },
            accept: {
                value: false,
                error: false,
            },
            defaultBackground: '#FFFFFF',
            customBackground: '#F9FCFF',
            error: false,
            bigImages: false, // Тумблер переключения отображения изображений
            alreadyEditing: false, // Правило уже редактируют
            appPath: process.env.VUE_APP_PATH_BACK,
        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            notify: (state) => state.popups.notify,
            switches: (state) => state.switches.items,
            movingSection: (state) => state.articles.movingSection,
            authors: (state) => state.articles.authors,
        }),
        isEdit() {
            return this.switches.ruleEdit.value;
        },
        isMovingRule() {
            return this.switches.moveRule.value;
        },
        currentAuthor() {
            return this.activeSection.author_id && this.authors.length > 0
                ? this.authors.find((item) => +item.id === +this.activeSection.author_id)
                : {};
        },
        movingAuthor() {
            return this.movingSection.author_id && this.authors.length > 0
                ? this.authors.find((item) => +item.id === +this.movingSection.author_id)
                : {};
        },
    },
    mounted() {
        this.updateBackground(this.customBackground);
    },
    beforeUnmount() {
        this.resetBackground();
        this.setMovingSection({});
    },
    watch: {
        movingSection() {
            this.error = false;
        },
    },
    methods: {
        ...mapActions('articles', [
            'moveArticleCategory',
        ]),
        ...mapMutations('articles', [
            'setMovingSection',
        ]),
        scrollToUp() {
            scrollTo('up');
        },
        send() {
            if (this.validate()) {
                this.$store.commit('articles/setCurrentAuthor', this.authors.find((item) => +item.id === +this.movingSection.author_id));
                this.moveArticleCategory({ id: this.detail.id, category_id: this.movingSection.id, operation: 'category' }).then(() => {
                    this.$router.push(this.detailPath);
                    this.$toast.show(this.notify.articleMoved);
                });
            }
        },
        validate() {
            let valid = true;
            if (!this.movingSection.id) {
                if (this.error) {
                    this.error = false;
                    setTimeout(() => {
                        this.error = true;
                    }, 150);
                } else {
                    this.error = true;
                }
                valid = false;
            }
            return valid;
        },
        updateBackground(color) {
            document.body.style.background = color;
        },

        resetBackground() {
            document.body.style.background = this.defaultBackground;
        },
    },
};
</script>

<style lang="sass">
</style>
