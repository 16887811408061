<template>
  <div class="">
    <main-header :isBottomHeader="false" :isCategoryHeader="false"></main-header>
    <div class="wrapper">
      <div class="center-block">
        <div class="logo">
          <svg-icon :name="'warning'" :width="80" :height="80" class="text-color-yellow"></svg-icon>
        </div>
        <div class="title mt12">
          <h3 class="text-bold text-size-26">Ошибка 404</h3>
        </div>
        <div class="text mt8">
          <p class="text-size-14 text-center">
            Страница не найдена
          </p>
        </div>
        <button class="btn mt32" @click="goToMain">
          <svg-icon class="mr4" :name="'send'" :width="16" :height="16"></svg-icon>
          <span>НА ГЛАВНУЮ</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
    components: {
        MainHeader,
        SvgIcon,
    },

    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
        goToMain() {
            this.$router.push('/');
        },
    },
};
</script>

<style lang="sass" scoped>
.wrapper
  padding-top: 24px
  height: 100%
  min-height: 100svh
  background: #F9FCFF
</style>
