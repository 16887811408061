import { Blockquote } from '@tiptap/extension-blockquote';
import articles from '@/store/modules/articles';

const BlockquoteBlock = Blockquote.extend({

    name: 'blockquoteBlock',
    content: 'inline*',
    addOptions() {
        return {
            user: false,
            HTMLAttributes: {},
        };
    },
    addAttributes() {
        return {
            'data-role': {},
            'data-name': {},
            'data-photo': {},
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div.blockquote',
                getAttrs: () => ({
                    'data-role': articles.state.currentAuthor.post,
                    'data-name': articles.state.currentAuthor.name,
                    'data-photo': articles.state.currentAuthor.avatar,
                }),
            },
        ];
    },
    renderHTML() {
        return ['div',
            {
                class: 'blockquote',
                'data-role': articles.state.currentAuthor.post,
                'data-name': articles.state.currentAuthor.name,
                'data-photo': articles.state.currentAuthor.avatar,
            },
            ['div', {
                class: 'blockquote__text',
            }, 0],
            ['div', { class: 'blockquote__profile' },
                ['div', { class: `blockquote__photo ${articles.state.currentAuthor.avatar ? '' : 'noimage'}`, 'data-char': articles.state.currentAuthor.name ? articles.state.currentAuthor.name.charAt(0) : '' },
                    ['img', { src: articles.state.currentAuthor.avatar ? process.env.VUE_APP_PATH_BACK + articles.state.currentAuthor.avatar : '' }]],
                ['div', { class: 'blockquote__info' },
                    ['div', { class: 'name', 'data-name': articles.state.currentAuthor.name }],
                    ['div', { class: 'role', 'data-role': articles.state.currentAuthor.post }],
                ],
            ],
        ];
    },
    addCommands() {
        return {
            toggleBlockquoteBlock: (user) => ({ commands }) => {
                this.options.userName = `${user.name}`;
                this.options.userRole = user.post;
                this.options.userPhoto = user.avatar ? process.env.VUE_APP_PATH_BACK + user.avatar : false;
                this.options.userLink = user.publicPath;
                return commands.insertContent({ type: this.name });
            },
        };
    },
});

export default BlockquoteBlock;
