<script>
export default {
    name: 'RadioGroup',
    props: {
        modelValue: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            default: 'radio-group',
        },
        disabled: Boolean,
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>

<template>
  <div class="radio-group" :class="{'disabled': disabled}">
    <label v-for="option in options" :key="option.value" class="radio-label">
      <input
        type="radio"
        :name="name"
        :disabled="disabled"
        :value="option.value"
        :checked="option.value === modelValue"
        @change="updateValue(option.value)"
      />
      {{ option.label }}
    </label>
  </div>
</template>

<style scoped>
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}
.radio-group.disabled{
  user-select: none;
  pointer-events: none;
}
.radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}

input[type="radio"] {
  width: 16px;
  cursor: pointer;
  height: 16px;
  border: 1px solid #A9A9A9;
  border-radius: 100%;
  position: relative;
}
input[type="radio"]:checked {
  border-color: #545454;

}
input[type="radio"]:checked::after {
  position: absolute;
  content: '';
  background: #545454;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
