<template>
  <div class="checkbox-group" :class="{'disabled': disabled}">
    <CheckBoxItem
      v-for="option in options"
      :disabled="disabled"
      :key="option.value"
      :label="option.label"
      :modelValue="modelValue.includes(option.value)"
      @update:modelValue="toggleCheckbox(option.value)"
    />
  </div>
</template>

<script>
import CheckBoxItem from '@/components/forms/CheckBoxItem.vue';

export default {
    components: { CheckBoxItem },
    props: {
        modelValue: Array,
        options: Array,
        disabled: Boolean,
    },
    emits: ['update:modelValue'],
    methods: {
        toggleCheckbox(value) {
            const newValues = [...this.modelValue];
            const index = newValues.indexOf(value);
            if (index === -1) {
                newValues.push(value);
            } else {
                newValues.splice(index, 1);
            }
            this.$emit('update:modelValue', newValues);
        },
    },
};
</script>

<style scoped>
.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkbox-group.disabled{
  user-select: none;
  pointer-events: none;
}
</style>
